export const environment = {
    production: false,
    useDevToken: false,
    devToken: '',
    name: 'preprod',
    version: '1.0.0',
    region: 'us-gov-west-1',
    url: 'https://safire-preprod.cristlgov.com/home',
    endpoint: 'https://preprod-be.safire-ai.com',
    sandboxEnabled: false,
    refreshEndpoint: 'https://preprod-be.cristlgov.com/auth/refreshToken',
    uamUrl: 'https://preprod.cristlgov.com', 
    cookieInfo: {
      domain: '.cristlgov.com'
    },
    amplify: {
      Auth: {
        region: 'us-gov-west-1',
        userPoolId: 'us-gov-west-1_W0qazGS5J',
        userPoolWebClientId: '467r82bttnretnqeogidn9at1l',
      },
    },
  };
  